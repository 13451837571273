// Make this flag available to the client-side (JavaScript) SDK
import { ElectionFeatures } from '@news-mono/common'
import { TogglesReduxState } from './feature-togglings'

export type SecurityFeatures =
    | 'sec-strict-hsts'
    | 'sec-enforce-csp'
    | 'sec-csp-reports'
    | 'sec-enable-untested-headers'

// Split out the custom feature toggles related to ad content
export type AdFeatures =
    | 'google-tag-manager'
    | 'ix-header-tag-wrapper'
    | 'gpt-ads'
    | 'debug-ads'
    | 'prebid-video'
    | 'paywall-bypass-header'
    | 'bonzai-truskin'
    | 'outstream'
    | 'sticky-mrec'
    | 'taboola'
    | 'taboola-regionals'
    | 'taboola-bb-vip-show-page'
    | 'perthnow-taboola-web-push'
    | '7-news-taboola-newsroom'
    | '7-news-taboola-web-push'
    | '7-news-adfixus-script-tag'
    | '7-news-skimlinks-script'
    | 'companion-ads'
    | 'live-ramp-ats-v2'
    | 'force-ads-hidden-until-loaded-false'
    | 'genwest-taboola'
    | 'genwest-ad-slots'
    | 'pn-skimlinks-script'
    | 'tn-homepage-odd-topic-ads'
    | 'tn-article-body-ads'
    | '7-news-2-top-leaderboard-ad'
    | 'tn-raise-homepage-adslot'
    | 'magnite-header-tag-wrapper'
    | 'magnite-custom-environment'
    | 'teads-article-ads'
    | 'taboola-skimlinks'
    | 'ad-refresh-enabled'
    | 'gumgum-ads'
    | 'breach-disable-ads'

export type SwgFeatures =
    | 'breach-puzzles-swg-30'
    | 'swg-breach-default'
    | 'swg-breach-albany-advertiser'
    | 'swg-breach-great-southern-herald'
    | 'swg-breach-augusta-margaret-river-times'
    | 'swg-breach-busselton-dunsborough-times'
    | 'swg-breach-broome-advertiser'
    | 'swg-breach-the-kimberley-echo'
    | 'swg-breach-geraldton-guardian'
    | 'swg-breach-midwest-times'
    | 'swg-breach-bunbury-herald'
    | 'swg-breach-harvey-waroona-reporter'
    | 'swg-breach-manjimup-bridgetown-times'
    | 'swg-breach-south-western-times'
    | 'swg-breach-kalgoorlie-miner'
    | 'swg-breach-narrogin-observer'
    | 'swg-breach-north-west-telegraph'
    | 'swg-breach-pilbara-news'
    | 'swg-breach-sound-telegraph'
    | 'swg-breach-countryman'
    | 'swg-entitlements-check'
    | 'swg-account-linking'
    | 'subscribe-with-google'
    | 'swg-campaign-offer'

// If you want these common features to appear client side ensure that in launch darkly the following checkbox is enabled
export type Features =
    | 'breach-regional-override'
    | 'breach-ab-testing'
    | 'launch-darkly-a-b-testing'
    | 'a-b-testing-features'
    | 'breach-ab-test-1'
    | 'breach-ab-test-2'
    | 'breach-v2-2024'
    | 'brightcove-player'
    | 'email-author'
    | 'refresh-on-inactive'
    | 'can-autoplay-video'
    | 'can-autoplay-muted-video'
    | 'can-autoplay-next'
    | 'related-content'
    | 'native-chartbeat'
    | 'newsletter-subscription-banner'
    | 'nielsen-video-events'
    | 'inline-card-video'
    | 'search'
    | 'canary'
    | 'world-collection'
    // | 'use-oovvuu-recommendation'
    | 'cricketball'
    | 'digital-subscriptions'
    | 'nielsen-video-events-inline-videos'
    | 'mmo-bypass'
    | 'ripple'
    | 'regionals'
    | 'immersive-story-article-template'
    | 'telethon-countdown'
    | 'running-parallax'
    | 'logo-takeover'
    | 'afl-draft'
    | 'simple-digital-subscription-form'
    | 'video-playlist'
    | 'cookie-consent'
    | 'puzzles-target'
    | 'the-west-live'
    | 'playlists-on-inline-videos'
    | 'force-west-live-activation'
    | 'video-series'
    | 'sport-breach-screen-variant'
    | 'the-west-rewards'
    | 'registration-wall'
    | 'comments'
    | 'hero-image-as-video-poster'
    | 'black-friday'
    | 'black-friday-promo-strap-style'
    | 'top-50-schools'
    | '50-off-afl-breach-screen'
    | 'eofy'
    | 'use-internal-content-api'
    | 'breach-190-year'
    | 'adobe-audience-manager'
    | 'adobe-fonts'
    | 'newsletter-subscriptions'
    | 'nielsen-subbrand'
    | 'app-upgrade-screen'
    | 'the-west-logo'
    | 'route-west-tonight'
    | '50-off-breach-discount'
    | 'south-west-curations'
    | 'sophi'
    | '7news-taboola-midarticle'
    | 'cng-to-perth-now-rebrand'
    | 'route-up-late'
    | 'puzzles'
    | 'digital-edition-redirects'
    | 'the-west-grand-final'
    | 'the-west-grand-final-collection'
    | 'perthnow-streamer-screamer-play-of-the-day'
    | '7ent-article-related'
    | '7news-contextual-link'
    | 'the-west-cnbc'
    | 'queen-elizabeth-tributes-route'
    | 'climate-change-home'
    | 'the-west-bar-regional'
    | 'perth-now-pride-logo'
    | 'feature-toggle-override'
    | 'subscriber-dropdown'
    | 'subscriber-feature-tooltip'
    | '7-news-lifestyle-article-related-card'
    | '7-news-lifestyle-four-column-taboola-feed'
    | '7-news-lifestyle-article-full-page-display'
    | '7-news-lifestyle-contextual-link'
    | 'hide-mobile-breaking-news-banner'
    | '7-news-sas-australia-article-related-card'
    | 'amp-read-more'
    | '7-news-dwts-contextual-link'
    | '7-news-politics-full-page-article-display'
    | '7-news-sport-article-full-page-display'
    | '7-news-tech-full-article-page-display'
    | '7-news-article-full-page-display'
    | '7-news-world-full-article-page-display'
    | '7-news-taboola-read-more-amp-articles'
    | 'live-coverage-driver'
    | '7-news-spotlight-full-article-display'
    | 'ipsos-tracking'
    | 'ipsos-tracking-amp'
    | 'foul-play-editorial-feature'
    | 'subs-campaign-eofy-weekend-digital'
    | 'default-price-increase'
    | 'subs-campaign-business-pack-2022'
    | 'subs-campaign-sport-pack-2022'
    | 'subs-campaign-bali-bombing-2022'
    | 'PerthNow-rightsiderail-desktop-digital-edition-driver'
    | 'PerthNow-in-article-digital-edition-driver'
    | '7-news-google-web-stories-home-page'
    | '7-news-navigation-video-hub'
    | 'the-west-increased-article-font'
    | 'commonwealth-games-4card-curation'
    | 'commonwealth-games-play-of-the-day'
    | 'exclude-video-series-from-playlists'
    | 'commonwealth-games-sports-page-curation'
    | 'timespool-navigation'
    | 'do-not-refresh-while-inactive'
    | '7-news-individual-video-watch-page'
    | '7-news-redirect-logic'
    | 'web-stories'
    | 'the-west-grand-final-collection-home-4'
    | 'the-west-grand-final-freo-collection-theme'
    | 'the-west-grand-final-collection-home-8'
    | '7-news-video-hub-topic-page'
    | 'queens-tribute-in-article-banners'
    | 'perthnow-play-of-the-day'
    | 'best-australian-yarn-curation-segment'
    | 'best-australian-yarn-route'
    | '7-news-video-hub-playlist-fix'
    | 'bali-bombings-feature-route'
    | 'subscriber-exclusive-subscriber-service-curation'
    | '7-news-cricket-fixture-scorecard'
    | 'exclusive_content'
    | 'gtm4'
    | 'BAY-top-14'
    | 'BAY-top-50'
    | 'tp-subscription-subscribe-button-design'
    | 'tp-subscription-new-breach-package-design'
    | 'tp-subscription-new-breach-package-background'
    | 'tp-subscription-breach-show-part-article'
    | 'tp-subscription-abandoned-cart'
    | 'tp-subscription-hero-variation'
    | 'teachers-offering'
    | 'university-offering'
    | 'genwest'
    | 'trading-up'
    | 'trading-up-stake-images'
    | '7-news-linkby-pubfeed'
    | '7news-mobile-video-autoplay'
    | '7-news-sponsored-disclaimer'
    | '7-news-afl-fixture-scoreboard'
    | 'smartocto'
    | '7-news-match-centre-odds-toggle'
    | 'video-hub'
    | 'the-west-match-centre'
    | 'the-west-afl-fixture-scoreboard'
    | 'datadog-client'
    | 'the-west-wafl-landing'
    | 'best-australian-yarn-gen-west'
    | 'mad-monday-route'
    | 'tw-1000-promo'
    | 'breachwall-banner'
    | '7-news-main-navigation-update'
    | '190-years-of-the-west'
    | '190-years-of-the-west-masthead'
    | '7news-tv-guide-navigation'
    | 'go-matildas-messages-route'
    | 'go-matildas-homepage-collection'
    | 'regional-readers-survey'
    | 'court-in-the-act'
    | 'best-australian-short-film'
    | '7-news-parsely-script'
    | '7-news-trendii-script'
    | 'vanishing-cousins-feature-route'
    | 'vanishing-cousins-sub-exclusive-banner'
    | 'vanishing-cousins-breach-screen'
    | '7-news-2-article-page'
    | '7-news-tv-guide-widgets'
    | '7news-real-estate-view-widget'
    | 'auth0'
    | 'third-party-noindex-nofollow-meta-tags'
    | '7-news-2-home-page-pill-menu'
    | '7-news-2-pill-menu'
    | '7-news-just-in-carousel'
    | '7-news-footer-newsletter'
    | '7-news-2-home-page'
    | 'inline-create-account-cta'
    | 'BASF-feature-page'
    | 'BASF-top-30'
    | '7-news-video-playlist-removal'
    | '7-news-newsletter-route'
    | 'web-app-download'
    | 'web-app-download-page'
    | '7-news-2-author-profile'
    | 'tn-enable-pagination'
    | 'tn-inline-video-enabled'
    | 'tn-promo-banner'
    | 'tn-article-driver-the-game-afl'
    | '7-news-app-smart-banner'
    | '7-news-app-smart-banner-android'
    | '7-news-2-video-hub'
    | 'car-expert-canonical'
    | '7-news-app-contextual-link'
    | '7-news-watch-7-plus-nav'
    | '7-news-app-site-promotion'
    | '7-news-app-page'
    | 'perthnow-new-auth-tenant'
    | 'live-event-pagination'
    | 'retire-ready-feature-page'
    | '7-news-search-bar'
    | 'paris-olympics-homepage-collection'
    | 'paris-olympics-collection-tally'
    | 'paris-olympics-2024'
    | 'paris-olympics-widgets'
    | '7-news-olympics-2024'
    | 'search-pagination'
    | 'app-smart-banner-ios'
    | 'app-smart-banner-android'
    | 'save-article-to-profile'
    | '7-news-sunrise-newsletter-iframe'
    | 'show-search-filter'
    | 'show-saved-articles-search-filter'
    | 'content-nav-mobile'
    | 'content-nav-desktop'
    | 'show-native-share-button'
    | 'perthnow-ngn-redesign-master'
    | 'perthnow-ngn-article-disabled'
    | 'display-event-latest-post'
    | 'perthnow-ngn-homepage-disabled'
    | 'perthnow-ngn-topic-disabled'
    | 'auth0-enhanced-signup-tracking'
    | 'display-survey-banner'
    | 'add-edition-to-news-ticker'
    | 'custom-saved-articles-app-ui'
    | 'perthnow-shop-now-banner'
    | '7-news-qld-election-widgets'
    | 'news-ticker-default-to-top-five'
    | 'add-local-business-to-structured-data'
    | 'show-trending-articles'
    | 'us-politics-homepage-collection'
    | 'notification-centre'
    | '7-news-afl-or-cricket-fixtures'
    | 'shorthand-article-enabled'
    | 'the-nightly-on-topic'
    | 'the-nightly-on-tomorrow-route'
    | 'the-nightly-leadership-home-segment'
    | '7-news-match-centre-scoreboard-btyb'
    | '7-news-match-centre-nbn-sponsor'
    | '7-news-inline-election-needle-widget'
    | 'perthnow-ngn-new-taxonomy'
    | 'breach-show-annual-package'
    | 'native-lazy-loading'
    | 'live-blog-milestones-teaser'
    | 'amp-redirect-to-canonical'
    | '7-news-wa-election-widgets'
    | '7-news-afl-finals-fixtures'
    | 'the-nightly-tomorrow-home-segment'
    | AdFeatures
    | SecurityFeatures
    | ElectionFeatures
    | SwgFeatures

export interface ABTestingFeature {
    variants: Array<ABTestingFeatureVariant>
}

export interface ABTestingFeatureVariant {
    on: { percentage: number; value: unknown }
    defaultValue: unknown
}
export type ABTestingFeatures = Omit<
    { [K in Features]?: ABTestingFeature },
    'a-b-testing-features'
>

export const testFeatureState: TogglesReduxState = {
    'breach-regional-override': false,
    'breach-ab-testing': true,
    'launch-darkly-a-b-testing': false,
    'breach-ab-test-1': 'a',
    'breach-ab-test-2': 1,
    'breach-v2-2024': true,
    'a-b-testing-features': {
        'breach-ab-test-1': {
            variants: [
                {
                    on: { percentage: 25, value: 'a' },
                    defaultValue: 'a',
                },
                {
                    on: { percentage: 50, value: 'b' },
                    defaultValue: 'a',
                },
                {
                    on: { percentage: 75, value: 'c' },
                    defaultValue: 'a',
                },
                {
                    on: { percentage: 100, value: 'd' },
                    defaultValue: 'a',
                },
            ],
        },
        'breach-ab-test-2': {
            variants: [
                {
                    on: { percentage: 25, value: 1 },
                    defaultValue: 1,
                },
                {
                    on: { percentage: 50, value: 2 },
                    defaultValue: 1,
                },
                {
                    on: { percentage: 75, value: 3 },
                    defaultValue: 1,
                },
                {
                    on: { percentage: 100, value: 4 },
                    defaultValue: 1,
                },
            ],
        },
    },
    'perthnow-taboola-web-push': true,
    'video-hub': true,
    'BAY-top-14': true,
    'BAY-top-50': true,
    'world-collection': true,
    'brightcove-player': true,
    'ix-header-tag-wrapper': true,
    'email-author': true,
    'google-tag-manager': true,
    'gpt-ads': true,
    'refresh-on-inactive': false,
    'can-autoplay-video': true,
    'can-autoplay-next': false,
    'can-autoplay-muted-video': false,
    'related-content': false,
    'prebid-video': true,
    search: false,
    'debug-ads': true,
    'newsletter-subscription-banner': false,
    'sticky-mrec': false,
    'nielsen-video-events': true,
    'inline-card-video': true,
    canary: false,
    // 'use-oovvuu-recommendation': false,
    cricketball: false,
    'digital-subscriptions': false,
    'bonzai-truskin': false,
    outstream: false,
    'nielsen-video-events-inline-videos': false,
    'mmo-bypass': false,
    'paywall-bypass-header': true,
    ripple: false,
    regionals: true,
    'immersive-story-article-template': false,
    'telethon-countdown': false,
    'running-parallax': false,
    'logo-takeover': false,
    'afl-draft': false,
    'simple-digital-subscription-form': false,
    'video-playlist': false,
    'cookie-consent': false,
    'cng-to-perth-now-rebrand': true,
    'the-west-live': false,
    'playlists-on-inline-videos': false,
    'force-west-live-activation': false,
    'video-series': true,
    'sport-breach-screen-variant': true,
    taboola: true,
    'taboola-regionals': true,
    'companion-ads': true,
    'the-west-rewards': true,
    'registration-wall': true,
    comments: false,
    'hero-image-as-video-poster': true,
    'black-friday': false,
    'black-friday-promo-strap-style': false,
    'top-50-schools': true,
    'use-internal-content-api': true,
    '50-off-afl-breach-screen': true,
    'breach-190-year': true,
    'breach-puzzles-swg-30': true,
    'adobe-audience-manager': false,
    'adobe-fonts': true,
    'newsletter-subscriptions': true,
    'nielsen-subbrand': true,
    'app-upgrade-screen': true,
    'the-west-logo': true,
    'route-west-tonight': true,
    '50-off-breach-discount': false,
    'south-west-curations': false,
    sophi: false,
    '7news-taboola-midarticle': true,
    'route-up-late': true,
    'puzzles-target': false,
    puzzles: true,
    'digital-edition-redirects': true,
    'the-west-grand-final': true,
    'the-west-grand-final-collection': true,
    'perthnow-streamer-screamer-play-of-the-day': true,
    'live-ramp-ats-v2': true,
    '7ent-article-related': true,
    '7news-contextual-link': true,
    'the-west-cnbc': true,
    'subscribe-with-google': false,
    'swg-breach-default': false,
    'swg-breach-albany-advertiser': false,
    'swg-breach-great-southern-herald': false,
    'swg-breach-augusta-margaret-river-times': false,
    'swg-breach-busselton-dunsborough-times': false,
    'swg-breach-broome-advertiser': false,
    'swg-breach-the-kimberley-echo': false,
    'swg-breach-geraldton-guardian': false,
    'swg-breach-midwest-times': false,
    'swg-breach-bunbury-herald': false,
    'swg-breach-harvey-waroona-reporter': false,
    'swg-breach-manjimup-bridgetown-times': false,
    'swg-breach-south-western-times': false,
    'swg-breach-kalgoorlie-miner': false,
    'swg-breach-narrogin-observer': false,
    'swg-breach-north-west-telegraph': false,
    'swg-breach-pilbara-news': false,
    'swg-breach-sound-telegraph': false,
    'swg-breach-countryman': false,
    'climate-change-home': false,
    'the-west-bar-regional': true,
    'taboola-bb-vip-show-page': true,
    'swg-entitlements-check': false,
    'perth-now-pride-logo': false,
    'feature-toggle-override': true,
    'subscriber-dropdown': true,
    'subscriber-feature-tooltip': true,
    '7-news-lifestyle-article-related-card': true,
    '7-news-lifestyle-four-column-taboola-feed': true,
    '7-news-lifestyle-article-full-page-display': true,
    '7-news-lifestyle-contextual-link': true,
    'hide-mobile-breaking-news-banner': true,
    '7-news-sas-australia-article-related-card': true,
    'amp-read-more': true,
    'swg-account-linking': false,
    '7-news-dwts-contextual-link': true,
    '7-news-politics-full-page-article-display': true,
    'force-ads-hidden-until-loaded-false': true,
    '7-news-sport-article-full-page-display': true,

    '7-news-tech-full-article-page-display': true,
    '7-news-article-full-page-display': true,
    '7-news-world-full-article-page-display': true,

    '7-news-taboola-read-more-amp-articles': true,
    'live-coverage-driver': true,
    '7-news-spotlight-full-article-display': true,
    '7-news-taboola-newsroom': true,
    'ipsos-tracking': true,
    'ipsos-tracking-amp': true,
    'foul-play-editorial-feature': true,
    '7-news-taboola-web-push': true,
    'subs-campaign-eofy-weekend-digital': false,
    'subs-campaign-sport-pack-2022': false,
    'subs-campaign-business-pack-2022': false,
    'subs-campaign-bali-bombing-2022': false,
    'default-price-increase': false,
    'PerthNow-rightsiderail-desktop-digital-edition-driver': false,
    'PerthNow-in-article-digital-edition-driver': false,
    '7-news-google-web-stories-home-page': true,
    '7-news-navigation-video-hub': true,
    'the-west-increased-article-font': false,
    'commonwealth-games-4card-curation': false,
    'commonwealth-games-play-of-the-day': true,
    'exclude-video-series-from-playlists': false,
    'commonwealth-games-sports-page-curation': false,
    'timespool-navigation': true,
    'do-not-refresh-while-inactive': false,
    '7-news-individual-video-watch-page': true,
    '7-news-redirect-logic': true,
    'web-stories': true,
    'the-west-grand-final-collection-home-4': false,
    'the-west-grand-final-freo-collection-theme': false,
    'the-west-grand-final-collection-home-8': false,
    '7-news-video-hub-topic-page': true,
    'queens-tribute-in-article-banners': true,
    'perthnow-play-of-the-day': true,
    'best-australian-yarn-curation-segment': true,
    'best-australian-yarn-route': true,
    '7-news-video-hub-playlist-fix': false,
    'bali-bombings-feature-route': true,
    'subscriber-exclusive-subscriber-service-curation': true,
    '7-news-cricket-fixture-scorecard': true,
    exclusive_content: true,
    gtm4: false,
    'tp-subscription-subscribe-button-design': false,
    'tp-subscription-new-breach-package-design': false,
    'tp-subscription-new-breach-package-background': false,
    'tp-subscription-breach-show-part-article': false,
    'tp-subscription-abandoned-cart': false,
    'tp-subscription-hero-variation': 'a',
    'teachers-offering': true,
    'university-offering': true,
    genwest: true,
    'genwest-taboola': false,
    'genwest-ad-slots': false,
    'trading-up': true,
    'trading-up-stake-images': true,
    '7-news-linkby-pubfeed': true,
    '7news-mobile-video-autoplay': true,
    '7-news-sponsored-disclaimer': true,
    '7-news-afl-fixture-scoreboard': true,
    smartocto: false,
    '7-news-match-centre-odds-toggle': true,
    'the-west-match-centre': true,
    'the-west-afl-fixture-scoreboard': true,
    'datadog-client': false,
    'best-australian-yarn-gen-west': true,
    'mad-monday-route': true,
    'the-west-wafl-landing': true,
    'tw-1000-promo': false,
    'breachwall-banner': true,
    '7-news-main-navigation-update': true,
    '190-years-of-the-west': true,
    '7-news-adfixus-script-tag': true,
    '190-years-of-the-west-masthead': false,
    '7news-tv-guide-navigation': true,
    '7-news-skimlinks-script': true,
    'pn-skimlinks-script': true,
    'go-matildas-homepage-collection': true,
    'regional-readers-survey': false,
    'court-in-the-act': true,
    'best-australian-short-film': true,
    '7-news-parsely-script': true,
    '7-news-trendii-script': true,
    'vanishing-cousins-feature-route': false,
    'vanishing-cousins-sub-exclusive-banner': true,
    'vanishing-cousins-breach-screen': false,
    '7-news-2-article-page': true,
    '7-news-tv-guide-widgets': true,
    '7news-real-estate-view-widget': true,
    auth0: true,
    'third-party-noindex-nofollow-meta-tags': true,
    '7-news-2-home-page-pill-menu': true,
    '7-news-2-pill-menu': true,
    '7-news-just-in-carousel': false,
    '7-news-footer-newsletter': true,
    '7-news-2-home-page': true,
    'inline-create-account-cta': true,
    'BASF-feature-page': true,
    'BASF-top-30': true,
    '7-news-video-playlist-removal': true,
    '7-news-newsletter-route': true,
    'web-app-download': true,
    '7-news-2-author-profile': true,
    'tn-enable-pagination': false,
    'tn-inline-video-enabled': true,
    'tn-promo-banner': false,
    'tn-homepage-odd-topic-ads': true,
    'tn-article-body-ads': true,
    'tn-article-driver-the-game-afl': false,
    '7-news-2-top-leaderboard-ad': true,
    'tn-raise-homepage-adslot': true,
    '7-news-app-smart-banner': true,
    '7-news-app-smart-banner-android': true,
    'magnite-header-tag-wrapper': true,
    '7-news-2-video-hub': true,
    'car-expert-canonical': true,
    '7-news-app-contextual-link': true,
    '7-news-watch-7-plus-nav': true,
    '7-news-app-site-promotion': true,
    '7-news-app-page': true,
    'teads-article-ads': true,
    'retire-ready-feature-page': false,
    '7-news-search-bar': true,
    'paris-olympics-homepage-collection': false,
    'paris-olympics-collection-tally': false,
    'paris-olympics-2024': false,
    'paris-olympics-widgets': false,
    '7-news-olympics-2024': true,
    'taboola-skimlinks': false,
    'search-pagination': true,
    'app-smart-banner-ios': true,
    'app-smart-banner-android': true,
    '7-news-sunrise-newsletter-iframe': true,
    'show-search-filter': true,
    'show-saved-articles-search-filter': true,
    'content-nav-mobile': true,
    'content-nav-desktop': true,
    'show-native-share-button': true,
    'perthnow-ngn-redesign-master': false,
    'perthnow-ngn-article-disabled': false,
    'perthnow-ngn-homepage-disabled': false,
    'perthnow-ngn-topic-disabled': false,
    'perthnow-ngn-new-taxonomy': false,
    'auth0-enhanced-signup-tracking': true,
    'display-survey-banner': true,
    'add-edition-to-news-ticker': true,
    'custom-saved-articles-app-ui': true,
    'perthnow-shop-now-banner': true,
    '7-news-qld-election-widgets': true,
    'news-ticker-default-to-top-five': true,
    'add-local-business-to-structured-data': true,
    'show-trending-articles': true,
    'us-politics-homepage-collection': true,
    'notification-centre': true,
    '7-news-afl-or-cricket-fixtures': true,
    'shorthand-article-enabled': true,
    'the-nightly-leadership-home-segment': true,
    '7-news-match-centre-scoreboard-btyb': true,
    '7-news-match-centre-nbn-sponsor': true,
    '7-news-inline-election-needle-widget': true,
    'breach-disable-ads': true,
    'federal-election-2022-pollie-rater': true,
    'breach-show-annual-package': true,
    'election-widget-test-page': true,
    'election-widget-preview-page': true,
    'federal-election-electorate-results-route': true,
    'federal-election-landing-page-the-seats': true,
    'federal-election-landing-page-the-race': true,
    'federal-election-landing-page-route': true,
    'federal-election-landing-page-the-swing': false,
    'federal-election-landing-page-map': false,
    'federal-election-homepage-the-seats': true,
    'federal-election-homepage-the-race': true,
    'federal-election-homepage-collection-position': 'low',
    'federal-election-homepage-collection': false,
    'federal-election-politics-collection': false,
    'federal-election-politics-collection-position': 'low',
    'federal-election-homepage-head-to-head': false,
    'federal-election-homepage-the-swing': false,
    'federal-election-homepage-map': false,
    'federal-election-navigation': true,
    'federal-election-sitewide-navigation': false,
    'federal-election-section-head-to-head': false,
    'federal-election-party-totals-route': true,
    'federal-election-pollie-rater': false,
    'federal-election-homepage-pollie-rater-promo': false,
    'wa-election-electorate-results-route': true,
    'wa-election-landing-page-the-seats': true,
    'wa-election-landing-page-the-race': true,
    'wa-election-landing-page-route': true,
    'wa-election-landing-page-the-swing': false,
    'wa-election-landing-page-map': false,
    'wa-election-homepage-the-seats': true,
    'wa-election-homepage-the-race': true,
    'wa-election-homepage-collection': true,
    'wa-election-homepage-collection-position': 'low',
    'wa-election-homepage-head-to-head': false,
    'wa-election-homepage-the-swing': false,
    'wa-election-homepage-map': false,
    'wa-election-navigation': true,
    'wa-election-sitewide-navigation': false,
    'wa-election-section-head-to-head': false,
    'wa-election-party-totals-route': true,
    'custom-election-embeds': true,
    'live-blog-milestones-teaser': true,
    'wa-election-politics-collection': true,
    'wa-election-politics-collection-position': 'low',
    'amp-redirect-to-canonical': false,
    '7-news-wa-election-widgets': false,
    '7-news-afl-finals-fixtures': false,
    'the-nightly-tomorrow-home-segment': true,
    'swg-campaign-offer': '',
}
